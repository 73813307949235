import { ClickOutside } from 'components/click-outside/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import './_styles.scss'
import { CommunityDropdownItem } from './dropdown-item'

interface CommunityDropdownProps {
  onCommunityChange?: any,
  push: any,
  showOnlyWhenMultiple?: boolean,
  showLabel?: boolean,
}

interface CommunityDropdownState {
  communities: any[],
  isLoading: boolean,
  selected: any,
  showList: boolean,
}

class CommunityDropdownComponent extends React.Component<CommunityDropdownProps, CommunityDropdownState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  constructor(props: CommunityDropdownProps) {
    super(props)
    this.state = {
      communities: [],
      isLoading: false,
      selected: null,
      showList: false,
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity]) => {
      this.setState({
        selected: activeCommunity.data,
      })
      this.loadCommunities()
    })
  }
  public render() {
    return this.state.communities.length > 0 ? this.renderCommunities() : null
  }

  private renderCommunities = () => {
    return this.state.isLoading ? <Loading size="64" /> : !this.props.showOnlyWhenMultiple || this.state.communities.length > 1 ? <div className="community-dropdown">
      <ClickOutside handleClickOutside={() => {
        if (this.state.showList) {
          this.setState({showList: !this.state.showList})
        }
      }}>
      <div className="dropdown-wrapper">
        {this.props.showLabel && this.state.communities.length > 1 ? <div className="dropdown-label">Select Newsletter</div> : null}
        <div className="dropdown-selected-item" onClick={this.toggle}>
          <CommunityDropdownItem community={this.state.selected} />
          <div className="dropdown-arrow">
            { this.state.showList ?
              <i className="fa fa-angle-up" aria-hidden="true" /> :
              <i className="fa fa-angle-down" aria-hidden="true" />
            }
          </div>
        </div>
        {this.state.showList &&
          <div className="dropdown-items-wrapper">
            <div className="close-button"></div>
            <div className="dropdown-items">
              {(this.state.communities).map((item, i) => {
                return <CommunityDropdownItem community={item} onChange={this.onSelectedCommunityChange} />
              })}
            </div>
          </div>
        }
      </div>
    </ClickOutside>
  </div> : null
  }

  private toggle = () => {
    this.setState({
      showList: !this.state.showList,
    })
  }
  private onSelectedCommunityChange = (community) => {
    if (this.props.onCommunityChange) {
      this.setState({
        showList: false,
        selected: community,
      })
      this.props.onCommunityChange(community)
    } else {
      this.switchCommunity(community)
    }
  }
  private switchCommunity = (community) => {
    // show loader
    this.setState({isLoading: true})
    this.context.user.switchCommunity(community.community_identifier).then(() => {
      this.setState({
        selected: community,
        showList: false,
        isLoading: false,
      })
      this.props.push('/')
    })
  }

  private loadCommunities = () => {
    return new Dataset().loadCommunityDataset('userNewsletters')
    .then((communities) => {
      this.setState({
        communities: communities[0],
      })
    })
  }
}
export const CommunityDropdown = connect(
  null,
  {
    push: Router.push,
  },
)(CommunityDropdownComponent)
