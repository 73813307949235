import * as Validation from 'components/validation/validations'
import * as React from 'react'
import classnames from "classnames";
import './_styles.scss'
import * as Modals from '../../../shared/modals';
import { Loading, SIZES } from '../../loading';
import { AjaxWrapper, HttpMethod } from '../../../generic/ajaxWrapper';
import { INVALID_IMAGE_URL_MESSAGE, SERVER_ERROR_MESSAGE } from '../constants';
import { IMAGE } from '../../../shared_server_client/constants';
import { SharedKeys, SharedStore } from '../../../shared/data-layer/sharedStore';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { UrlInput } from '../../input/component';
import { AjaxError } from 'rxjs/ajax';

interface image {
  imageId: string
  uploadUrl: string
  cdnUrl: string,
  height: number,
  width: number,
  colors: string[],
  isSelected?: boolean,
}

interface ImageGalleryState extends Modals.ModalComponentState {
  isSaving: boolean,
  isLoading: boolean,
  useUrl: boolean,
  validUrl: boolean,
  isEditingImage: boolean,
  deleteUserModal: boolean,
  sourceUrl: string,
  imagesList: image[],
  errorMessage: string,
  sourceErrorMessage: string,
  deleteImageIndex: number,
  selectedImageId: string,
}

interface ImageGalleryProps extends Modals.ModalComponentProps{
  onSelectImageFromGallery: (selectedImage: {
    url: string;
    imageWidth: number;
    imageHeight: number;
  }) => void
}

export const InitialGalleryModalState = {
  isSaving: false,
  isLoading: false,
  useUrl: false,
  validUrl: false,
  isEditingImage: false,
  deleteUserModal: false,
  sourceUrl: '',
  imagesList: [],
  errorMessage: '',
  sourceErrorMessage: '',
  deleteImageIndex: -1,
  selectedImageId: '',
}

export class ImageGalleryModal extends Modals.GenericModalComponent<ImageGalleryProps, ImageGalleryState> {
  public static key: string = 'ImageGalleryModal'
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: ImageGalleryProps) {
    super(props, ImageGalleryModal.key)
    this.state = InitialGalleryModalState
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        this.communityId = activeCommunity.communityId
        this.load()
      })
  }

  public renderChildren(data) {
    return this.state.isLoading || this.state.isSaving
      ? <Loading size={SIZES.LARGE}/>
      : <div className="image-modal-body">
        <div className="body-container">
          <div className="first-row-container d-flex justify-content-end w-100">
            {this.renderFirstRow()}
          </div>
        </div>
        {
          this.state.errorMessage &&
          <div className="error-container">
            {this.state.errorMessage}
          </div>
        }
        <div className={'sche-images-gallery d-flex flex-wrap'}>
          {this.state.imagesList.map((image, index) => (
            <div className={'sche-image-wrap rounded position-relative' + (image.isSelected ? ' --selected' : '')}
                 key={index}>
              <div className='sche-dimensions position-absolute'>
                {`${image.width} x ${image.height}`}
              </div>
              <img src={image.cdnUrl}
                   alt="image description"
                   className={'sche-image w-100 h-100 rounded'}
                   onClick={() => this.onImageSelect(image)}
              />
              <button
                className="sche-view-btn position-absolute rounded-circle btn btn-danger p-0 d-flex align-items-center"
                type="button"
                onClick={() =>
                  window.open(image.cdnUrl.split('?')[0], '_blank')
                }
              >
                <i className="fa fa-eye"></i>
              </button>
              <button type={'button'}
                      className={'sche-btn-delete position-absolute rounded-circle btn btn-danger p-0 d-flex align-items-center'}
                      onClick={() => this.deleteImageConfirmation(index)}>
                <i className="fas fa-trash"></i>
              </button>
            </div>))
          }
        </div>
        {this.renderDeleteImageModal()}
      </div>
  }

  public load() {
    this.setState({
      isLoading: true,
    })
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/gallery`
    AjaxWrapper.ajax(url, HttpMethod.GET, null)
      .then((response: image[]) => {
        this.setState({
          isLoading: false,
          imagesList: response
        })
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
          isLoading: false,
        })
      })
  }

  protected getTitleText(data: any): string {
    return 'Image Library'
  }

  protected xButtonClick(data: any) {
    this.cancelUpload()
    this.props.closeModal(ImageGalleryModal.key)
  }

  protected handleKeyPress = (target: any) => {
    if (target.charCode === 13 && this.state.validUrl) {
      this.validateFetch(this.state.sourceUrl)
    }
  }

  protected updateSourceUrl = (e) => {
    this.validateUrl(e.target.value)
    this.setState({sourceUrl: e.target.value})
  }

  protected validateUrl = (url: string) => {
    this.setState({
      errorMessage: '',
      validUrl: this.state.useUrl ? Validation.validateUrl(url).valid : Validation.validateImageUrl(url).valid,
    })
  }

  protected fetch = (inputUrl: string): Promise<any> => {
    const imageValidateEndpoint: string =  `${AjaxWrapper.getServerUrl()}/${this.communityId}/gallery/source`
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {imageUrl: inputUrl})
  }

  protected getFooterContent() {
    return (
        <div className="footer-buttons">
          <Button
              className="primary"
              onClick={() => this.ok()}
              disabled={!this.state.selectedImageId}
          >
            OK
          </Button>
        </div>
    );
  }

  protected validateFetch = (inputUrl: string) => {
    this.setState({isSaving: true})
    return this.fetch(inputUrl)
      .then((result: image) => {
        if (result.imageId) {
          this.state.imagesList.push(result)
          this.setState({isSaving: false})
          this.onImageSelect(result)
        } else {
          this.setState({
            sourceErrorMessage: INVALID_IMAGE_URL_MESSAGE,
            isSaving: false,
            validUrl: false,
          })
        }
      })
      .catch((error: AjaxError) => {
        this.setState({
          sourceErrorMessage: ((error.response ||{}).error || {}).message || SERVER_ERROR_MESSAGE,
          isSaving: false,
          validUrl: false,
        })
      })
  }

  private renderFirstRow = () => {
    const data = this.props.data
    return <div
      className={classnames('image-loader-and-buttons-container justify-content-end w-100')}>
      {this.renderInputBox(data)}
      <div className="buttons-container mr-0 flex-shrink-0">
        {data.typeId
          ? this.renderFirstRowEdit(data)
          : this.renderFirstRowNew(data)
        }
      </div>
    </div>
  }

  private renderUploadAndCancel = (data) => {
    return <div>
      <span
        className={classnames('upload-btn mr-2',
          {'clickable-item': this.state.validUrl, 'not-allowed': !this.state.validUrl})}
        onClick={() => this.validateFetch(this.state.sourceUrl)}>Upload
      </span>
      <span
        className={classnames('delete-btn', {'clickable-item': true})}
        onClick={this.cancelUpload}>Cancel
      </span>
    </div>
  }

  private renderBrowseAndUseUrl = (data) => {
    return <div>
      <div className="browse-delete d-flex mb-0">
        <span className="browse-btn mr-0 clickable-item">
          <label htmlFor="gallery_image" className="clickable-item">
            Browse
          </label>
          <input type="file"
                 className="hidden"
                 onChange={(e) => this.uploadImage(e.target.files[0])}
                 id="gallery_image"
                 name="gallery_image"
                 accept="image/png, image/jpeg, image/gif">
          </input>
        </span>
        <span
          className="use-url-btn clickable-item"
          onClick={this.useUrl}>Use URL</span>
      </div>
    </div>
  }

  private renderFirstRowNew = (data) => {
    if (!this.state.useUrl) {
      return this.renderBrowseAndUseUrl(data)
    } else {
      return this.renderUploadAndCancel(data)
    }
  }

  private renderFirstRowEdit = (data) => {
    if (!data.isDirty && !this.state.isEditingImage && (!this.state.useUrl)) {
      return <span
        className={classnames('change-btn', {'clickable-item': true})}
        onClick={this.onChangeClick}>Change
      </span>
    } else {
      if (this.state.useUrl) {
        return this.renderUploadAndCancel(data)
      } else {
        if (this.state.isEditingImage) {
          return this.renderBrowseAndUseUrl(data)
        }
      }
    }
  }

  private renderInputBox = (data) => {
    if (this.state.useUrl) {
      return <div className={'w-100'}>
        <Row form>
          <Col md={12}>
            <UrlInput
              errorMessage={this.state.sourceErrorMessage}
              onChange={this.updateSourceUrl}
              onKeyPress={this.handleKeyPress}
              placeholder={'Please input your image url here'}
              valid={this.state.validUrl}
              value={this.state.sourceUrl || data.imageUrl}/>
          </Col>
        </Row>
      </div>
    }
  }

  private uploadImage = (image: any) => {
    this.setState({
      isSaving: true,
      useUrl: false,
      errorMessage: '',
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/gallery`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: image) => {
        this.state.imagesList.push(hostedImage)
        this.setState({
          isSaving: false
        })
        this.onImageSelect(hostedImage)
      })
      .catch((error: AjaxError) => {
        this.setState({
          errorMessage: ((error.response ||{}).error || {}).message || SERVER_ERROR_MESSAGE,
          isSaving: false,
        })
      })
  }

  private onImageSelect(image: image) {
    this.state.imagesList.map(x => x.isSelected = false)
    image.isSelected = true
    this.setState({
      selectedImageId: image.imageId,
      imagesList: this.state.imagesList
    })
  }

  private ok() {
    this.setState({
      isSaving: true,
    })
    const image = this.state.imagesList.find((e) => e.imageId === this.state.selectedImageId)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/gallery/image/${image.imageId}`
    return AjaxWrapper.ajax(url, HttpMethod.PUT, null)
      .then((response: image) => {
        this.setState({
          isSaving: false,
          selectedImageId: ''
        })
        this.state.imagesList.map(x => x.isSelected = false)
        image.isSelected = true
        this.props.onSelectImageFromGallery({url: response.cdnUrl,imageWidth: response.width, imageHeight: response.height})
        this.props.closeModal(ImageGalleryModal.key)
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
          isSaving: false,
        })
      })
  }

  private deleteImageConfirmation(index: number) {
    this.setState({
      deleteUserModal: true,
      deleteImageIndex: index
    })
  }

  private deleteImage() {
    this.setState({
      isSaving: true,
    })
    const imageId = this.state.imagesList[this.state.deleteImageIndex].imageId
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/gallery/image/${imageId}`
    AjaxWrapper.ajax(url, HttpMethod.DELETE, null)
      .then(() => {
        this.state.imagesList.splice(this.state.deleteImageIndex, 1)
        this.setState({
          isSaving: false,
          deleteUserModal: false,
          deleteImageIndex: -1
        })
        this.props.onSelectImageFromGallery({url: '',imageWidth: 0, imageHeight: 0})
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        })
      })
  }

  private cancelUpload = () => {
    this.setState({
      useUrl: false,
      errorMessage: '',
      sourceErrorMessage: '',
      sourceUrl: '',
      validUrl: false
    })
  }

  private onChangeClick = () => {
    this.setState({isEditingImage: true})
  }

  private useUrl = () => {
    this.setState({useUrl: true})
  }

  private closeDeleteModal = () => {
    this.setState({
      deleteUserModal: false,
      deleteImageIndex: -1
    })
  }

  private renderDeleteImageModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.deleteUserModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>Are you sure you want to delete this image?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.deleteImage()}>
              Yes
            </Button>
            <Button onClick={this.closeDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
